import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public form: FormGroup;
  public submitted = false;
  public show = false;
  public hasNoCenter = true;
  public text = '';
  public datenschutz = false;
  private centerId = 0;
  private noPriceCenter = false;

  constructor(
    public fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.id === 'V2' || params.id === 'v2') {
        this.hasNoCenter = false;
        this.noPriceCenter = true;
      } else {
        this.centerId = params.id;
      }
      this.setValueByCenter();
    });
    this.form = this.fb.group({
      company: ['', Validators.required],
      firstname: ['', Validators.required],
      salutation: ['', Validators.required],
      lastname: ['', Validators.required],
      street: ['', Validators.required],
      zipcode: ['', Validators.required],
      city: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      others: [''],
      checkboxAdvertising: [false],
      checkboxOther: [false],
    });
  }
  title = 'Kontaktformular';

  submitForm() {
    this.submitted = true;
    if (!this.noPriceCenter) {
      this.form.value.centerId = this.centerId;
    } else {
      this.form.value.centerId = 0;
    }
    this.http
      .post(environment.api + '/Contact/', this.form.value)
      .subscribe((res) => (this.show = true));
  }

  public showDatenschutz() {
    this.datenschutz = true;
  }

  public hideDatenschutz() {
    this.datenschutz = false;
  }

  private setValueByCenter() {
    if (this.noPriceCenter) {
      this.text = environment.text.noPrice;
    } else {
      if (this.centerId > 0) {
        const costs = (environment.costs as any)[this.centerId];
        if (!costs) {
          return;
        }
        let textValue = (environment.text as any)[this.centerId];
        if (!textValue) {
          textValue = environment.text.default;
        }
        this.text = textValue.replace('##costs##', costs);
        this.hasNoCenter = false;
      }
    }
  }
}
