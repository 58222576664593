
  <h2>Datenschutzerklärung Weischer.Cinema Regio GmbH</h2>
  <h5>Art. 1 Datenschutz für das Kontaktformular von Weischer.Cinema Regio</h5>
  <p>
    Die Weischer.Cinema Regio GmbH hat sich bei der Gestaltung dieser Website zur Einhaltung der anwendbaren Rechtsvorschriften, zum Schutz personenbezogener Daten sowie zur Datensicherheit verpflichtet. Sie verarbeitet Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen der EU, insbesondere der Datenschutzgrundverordnung (DSGVO, TKG 2003).
    Im Folgenden möchten wir Sie davon in Kenntnis setzen, unter welchen Umständen wir Informationen von Ihnen sammeln, wie wir damit umgehen und wem wir sie gegebenenfalls zu welchem Zweck zur Verfügung stellen.
  </p>
  <h5>
    Art. 2 Arten der zu verarbeiteten Daten
  </h5>
  <p>
    Über unsere Website bringen wir keine personenbezogenen Daten von Ihnen in Erfahrung, es sei denn, dass Sie uns diese durch Kontaktierung Ihrerseits zur Verfügung stellen.
    Wir verarbeiten von Ihnen ausschließlich nach Ihrer Kontaktaufnahme über dieses Formular Bestandsdaten, wie Namen, sowie postalische Adressen, Telefonnummern, Faxnummern und E-Mail-Adressen sowie IP-Adressen.
  </p>
  <h5>
    Art. 3 Zweck der Datenverarbeitung
  </h5>
  <p>
    Ihre Daten speichern und verarbeiten wir, damit wir Kontaktanfragen von Ihnen beantworten können, vor allem Ihnen ein Angebot erstellen, sowie vorgegebene IT-Sicherheitsmaßnahmen einhalten können.
  </p>
  <h5>
    Art. 4 Datenübermittlung an Dritte
  </h5>
  <p>
    Wir geben Ihre Daten grundsätzlich nur unter besonderen Voraussetzungen weiter. Eine Weitergabe der Daten erfolgt nur an Auftragsverarbeiter oder Dritte, wenn Sie hierin eingewilligt haben nach Art. 6 Abs. 1 lit a oder hierfür eine rechtliche Verpflichtung besteht nach § 96 Abs. 3 TKG oder die Übermittlung der Daten an Dritte, gemäß Art. 6 Abs. 1 b DSGVO zur Vertragserfüllung erforderlich ist, bspw. bei Versandunternehmen oder Finanzämtern, oder aber beim Einsatz von IT-Unternehmen zur Pflege der Hardware und Software.
    Bei einer vertraglich vereinbarten Auftragsverarbeitung halten wir uns die Vorschriften der Art. 28 ff. DSGVO.
  </p>
  <h5>
    Art. 5 Ihre Rechte
  </h5>
  <p>
    Sie haben das Recht, Informationen darüber zu erhalten, ob und wenn ja welche Daten von Ihnen entsprechend Art. 15 DSGVO Daten von uns verarbeitet werden. Zudem dürfen Sie nach Art. 16 DSGVO die Vervollständigung oder Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen. Weiterhin haben Sie nach Art. 17 DSGVO das Recht auf Löschung, bzw. nach Art. 18 DSGVO auf eine Einschränkung der Verarbeitung der Daten. Schließlich können Sie verlangen, dass die entsprechenden Daten nach Art. 20 DSGVO von uns an andere Verantwortliche übermittelt werden dürfen. Im Übrigen haben Sie das Recht nach Art. 77 DSGVO, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
  </p>
  <h5>
    Art. 6 Widerrufsrecht und Widerspruchsrecht
  </h5>
  <p>
    Sie dürften Ihre zunächst erteilten Einwilligungen nach Art. 7 Abs. 3 DSGVO mit künftiger Wirkung widerrufen und nach Art. 21 DSGVO der künftigen Verarbeitung Ihrer Daten jederzeit widersprechen
  </p>
  <h5>
    Art. 7 Kontakt
  </h5>

    Sollten Sie Fragen und Anregungen zu dieser Datenschutzerklärung haben, wenden Sie sich bitte an:
    <dl>
      <dd>
        Dr. Uwe Schläger <br/>datenschutz nord GmbH
      </dd>
      <dd>
        Telefon: +49421 6966320
      </dd>
      <dd>
        <a href="mailto: office@datenschutz-nord.de">E-Mail: office@datenschutz-nord.de</a>
      </dd>
      <dd>
        <a href="http://www.datenschutz-nord-gruppe.de" target="_new">www.datenschutz-nord-gruppe.de</a>
      </dd>
    </dl>
    Version 3.0 vom 01.12.2018 (Änderungen und Anpassungen ausdrücklich vorbehalten)
