// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Ist bei aktuellen Publishsettings relevant
export const environment = {
  production: false,
  api: 'https://kontaktformularserver20210204103524.azurewebsites.net',
  text: {
    default:
      '<p>Kino wirkt! Die einzigartige Kombination aus beeindruckendem Sounderlebnis und hervorragender Bildqualität verhilft Ihnen zu einer optimalen Ansprache potenzieller Kunden. Sie schaffen eindrückliche Markenbilder, die sich in der Erinnerung verankern und Kaufanreize auslösen.</p><p>Kinowerbung kann lokal, regional und national gebucht werden – für jeden Kunden und jedes Budget. Als erfahrene Kinospezialisten wissen wir worauf es ankommt und unterstützen Sie gerne auch bei der Produktion Ihres Werbespots.</p><p>Und so einfach geht das: Klicken Sie auf das untenstehende Formular, füllen Sie es aus und binnen 24 Stunden kommt Ihr persönlicher Ansprechpartner von Kinowerbung.de auf Sie zu. Ihr Ansprechpartner berät Sie und legt Ihnen ein individuell ausgearbeitetes Angebot vor. Oder buchen Sie Ihre Werbung schnell und bequem auf <a href="http://www.kinowerbung.de" target="_blank">www.kinowerbung.de</a></p>',
    noPrice:
      '<p>Kino wirkt! Die einzigartige Kombination aus beeindruckendem Sounderlebnis und hervorragender Bildqualität verhilft Ihnen zu einer optimalen Ansprache potenzieller Kunden. Sie schaffen eindrückliche Markenbilder, die sich in der Erinnerung verankern und Kaufanreize auslösen.</p><p>Kinowerbung kann lokal, regional und national gebucht werden – für jeden Kunden und jedes Budget. Als erfahrene Kinospezialisten wissen wir worauf es ankommt und unterstützen Sie gerne auch bei der Produktion Ihres Werbespots.</p><p>Und so einfach geht das: Klicken Sie auf das untenstehende Formular, füllen Sie es aus und binnen 24 Stunden kommt Ihr persönlicher Ansprechpartner von Kinowerbung.de auf Sie zu. Ihr Ansprechpartner berät Sie und legt Ihnen ein individuell ausgearbeitetes Angebot vor. Oder buchen Sie Ihre Werbung schnell und bequem auf <a href="http://www.kinowerbung.de" target="_blank">www.kinowerbung.de</a></p>',
  },
  costs: {
    101300: '€ 15,00',
    103800: '€ 15,00',
    107700: '€ 15,00',
    110000: '€ 20,00',
    110100: '€ 20,00',
    111600: '€ 30,00',
    112800: '€ 30,00',
    114300: '€ 15,00',
    124400: '€ 15,00',
    125300: '€ 15,00',
    132300: '€ 30,00',
    141500: '€ 40,00',
    142500: '€ 18,00',
    169800: '€ 15,00',
    179000: '€ 18,00',
    183200: '€ 15,00',
    190100: '€ 40,00',
    190200: '€ 22,00',
    195900: '€ 18,00',
    213000: '€ 18,00',
    221200: '€ 15,00',
    223500: '€ 15,00',
    230400: '€ 15,00',
    232200: '€ 15,00',
    232299: '€ 15,00',
    233300: '€ 15,00',
    236300: '€ 15,00',
    247500: '€ 15,00',
    247600: '€ 15,00',
    267400: '€ 15,00',
    286200: '€ 40,00',
    289500: '€ 15,00',
    310800: '€ 15,00',
    312300: '€ 15,00',
    334500: '€ 22,00',
    340200: '€ 15,00',
    340700: '€ 30,00',
    345000: '€ 25,00',
    345200: '€ 15,00',
    354000: '€ 15,00',
    363600: '€ 15,00',
    364700: '€ 20,00',
    367700: '€ 20,00',
    372900: '€ 55,00',
    373500: '€ 15,00',
    376100: '€ 15,00',
    392900: '€ 20,00',
    394100: '€ 25,00',
    411900: '€ 20,00',
    412000: '€ 20,00',
    412300: '€ 15,00',
    414400: '€ 15,00',
    414800: '€ 15,00',
    434000: '€ 18,00',
    435200: '€ 15,00',
    214600: '€ 22,00',
    214900: '€ 15,00',
    182500: '€ 22,00',
    445800: '€ 15,00',
    216000: '€ 20,00',
    216200: '€ 15,00',
    293700: '€ 15,00',
    107100: '€ 20,00',
    257200: '€ 15,00',
    219500: '€ 15,00',
    219600: '€ 15,00',
    293900: '€ 15,00',
    362200: '€ 18,00',
    219900: '€ 18,00',
    295000: '€ 15,00',
    108500: '€ 15,00',
    324400: '€ 15,00',
    334400: '€ 15,00',
    228800: '€ 15,00',
    133000: '€ 15,00',
    166800: '€ 18,00',
    152400: '€ 15,00',
    151900: '€ 20,00',
    151700: '€ 18,00',
    144200: '€ 15,00',
    309600: '€ 25,00',
    148400: '€ 22,00',
    337300: '€ 20,00',
    305900: '€ 15,00',
    308700: '€ 22,00',
    311300: '€ 15,00',
    123400: '€ 18,00',
    347100: '€ 15,00',
    341500: '€ 18,00',
    354300: '€ 15,00',
    340800: '€ 15,00',
    329100: '€ 15,00',
    329400: '€ 15,00',
    315800: '€ 15,00',
    316500: '€ 15,00',
    303500: '€ 15,00',
    317900: '€ 15,00',
    318200: '€ 15,00',
    140900: '€ 15,00',
    142700: '€ 15,00',
    144600: '€ 22,00',
    355600: '€ 25,00',
    424100: '€ 15,00',
    137700: '€ 15,00',
    423700: '€ 15,00',
    237200: '€ 20,00',
    139000: '€ 18,00',
    279200: '€ 38,00',
    285400: '€ 30,00',
    352800: '€ 22,00',
    364500: '€ 22,00',
    375600: '€ 22,00',
    394300: '€ 22,00',
    343400: '€ 15,00',
    150900: '€ 15,00',
    290900: '€ 20,00',
    182000: '€ 22,00',
    421000: '€ 15,00',
    371700: '€ 15,00',
    427100: '€ 20,00',
    344300: '€ 15,00',
    158200: '€ 22,00',
    248300: '€ 15,00',
    313800: '€ 22,00',
    161000: '€ 20,00',
    368000: '€ 20,00',
    161600: '€ 15,00',
    215700: '€ 25,00',
    215900: '€ 15,00',
    216900: '€ 18,00',
    333700: '€ 20,00',
    187000: '€ 15,00',
    353300: '€ 20,00',
    396700: '€ 15,00',
    165600: '€ 22,00',
    165800: '€ 18,00',
    353800: '€ 35,00',
    218900: '€ 25,00',
    426300: '€ 15,00',
    434900: '€ 15,00',
    258300: '€ 23,00',
    397400: '€ 15,00',
    168100: '€ 18,00',
    260700: '€ 15,00',
    392500: '€ 15,00',
    220900: '€ 18,00',
    345400: '€ 20,00',
    341900: '€ 15,00',
    172500: '€ 15,00',
    229300: '€ 18,00',
    229000: '€ 18.00',
    229200: '€ 18,00',
    190700: '€ 50,00',
    190800: '€ 33,00',
    191100: '€ 18,00',
    368900: '€ 15,00',
    424700: '€ 15,00',
    247900: '€ 18,00',
    435100: '€ 15,00',
    420800: '€ 15,00',
    154800: '€ 15,00',
    122700: '€ 15,00',
    122800: '€ 15,00',
    151500: '€ 15,00',
    156800: '€ 15,00',
    157200: '€ 15,00',
    172000: '€ 15,00',
    384400: '€ 15,00',
    339500: '€ 15,00',
    280100: '€ 30,00',
    336600: '€ 23,00',
    439100: '€ 20,00',
    140400: '€ 25,00',
    291700: '€ 15,00',
    319700: '€ 15,00',
    308900: '€ 22,00',
    146100: '€ 20,00',
    334300: '€ 22,00',
    344900: '€ 15,00',
    335100: '€ 38,00',
    337500: '€ 30,00',
    339600: '€ 39,00',
    156100: '€ 22,00',
    334200: '€ 15,00',
    320100: '€ 22,00',
    340900: '€ 20,00',
    433700: '€ 15,00',
    342600: '€ 18,00',
    342200: '€ 31,00',
    342500: '€ 15,00',
    210600: '€ 25,00',
    210800: '€ 18,00',
    186400: '€ 16,00',
    259500: '€ 15,00',
    259600: '€ 15,00',
    266200: '€ 15,00',
    267800: '€ 15,00',
    402100: '€ 15,00',
    100600: '€ 15,00',
    109800: '€ 23,00',
    103000: '€ 15,00',
    104900: '€ 18,00',
    105300: '€ 18,00',
    387000: '€ 15,00',
    108200: '€ 15,00',
    355000: '€ 15,00',
    436000: '€ 15,00',
    106900: '€ 15,00',
    122900: '€ 15,00',
    340000: '€ 22,00',
    109600: '€ 15,00',
    139600: '€ 18,00',
    390300: '€ 18,00',
    252800: '€ 22,00',
    445200: '€ 15,00',
    343700: '€ 15,00',
    325400: '€ 15,00',
    318700: '€ 15,00',
    235200: '€ 15,00',
    288000: '€ 22,00',
    239500: '€ 15,00',
    155000: '€ 15,00',
    382100: '€ 18,00',
    387700: '€ 15,00',
    323800: '€ 15,00',
    419500: '€ 15,00',
    156300: '€ 15,00',
    351000: '€ 15,00',
    427700: '€ 18,00',
    159100: '€ 32,00',
    218200: '€ 15,00',
    218800: '€ 15,00',
    355500: '€ 15,00',
    245800: '€ 15,00',
    269200: '€ 15,00',
    202400: '€ 15,00',
    117100: '€ 15,00',
    208300: '€ 20,00',
    208400: '€ 18,00',
    208500: '€ 23,00',
    211400: '€ 15,00',
    211600: '€ 15,00',
    123700: '€ 15,00',
    124600: '€ 15,00',
    127400: '€ 15,00',
    130900: '€ 15,00',
    357300: '€ 15,00',
    333000: '€ 15,00',
    306700: '€ 25,00',
    290000: '€ 15,00',
    334900: '€ 15,00',
    309500: '€ 22,00',
    291000: '€ 15,00',
    311400: '€ 15,00',
    312100: '€ 15,00',
    314300: '€ 15,00',
    125000: '€ 15,00',
    352200: '€ 15,00',
    293000: '€ 15,00',
    315000: '€ 15,00',
    129300: '€ 15,00',
    340100: '€ 15,00',
    140600: '€ 18,00',
    145400: '€ 32,00',
    145600: '€ 25,00',
    150600: '€ 35,00',
    150400: '€ 22,00',
    149800: '€ 22,00',
    172700: '€ 15,00',
    392400: '€ 15,00',
    150500: '€ 15,00',
    188000: '€ 15,00',
    214700: '€ 15,00',
    157800: '€ 15,00',
    163100: '€ 15,00',
    233700: '€ 15,00',
    167500: '€ 15,00',
    168500: '€ 15,00',
    136600: '€ 15,00',
    137000: '€ 15,00',
    409500: '€ 15,00',
    387600: '€ 15,00',
    358700: '€ 15,00',
    365600: '€ 15,00',
    199100: '€ 15,00',
    234800: '€ 15,00',
    138400: '€ 15,00',
    204500: '€ 15,00',
    243000: '€ 15,00',
    216100: '€ 15,00',
    345500: '€ 15,00',
    199000: '€ 15,00',
    363800: '€ 15,00',
    224300: '€ 15,00',
    375700: '€ 15,00',
    285700: '€ 15,00',
    282100: '€ 15,00',
    278900: '€ 15,00',
    352700: '€ 15,00',
    207800: '€ 15,00',
    394600: '€ 15,00',
    435700: '€ 15,00',
    112500: '€ 15,00',
    271900: '€ 15,00',
    121900: '€ 15,00',
    121500: '€ 15,00',
    121000: '€ 15,00',
    158600: '€ 15,00',
    313000: '€ 15,00',
    313400: '€ 15,00',
    313100: '€ 15,00',
    312800: '€ 15,00',
    162000: '€ 15,00',
    163600: '€ 15,00',
    256300: '€ 15,00',
    414100: '€ 15,00',
    264100: '€ 15,00',
    233600: '€ 15,00',
    181100: '€ 15,00',
    181500: '€ 15,00',
    180400: '€ 15,00',
    123600: '€ 15,00',
    198000: '€ 15,00',
    384500: '€ 15,00',
    384000: '€ 15,00',

  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
