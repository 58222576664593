import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dataschutz',
  templateUrl: './dataschutz.component.html',
  styleUrls: ['./dataschutz.component.scss']
})
export class DataschutzComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
