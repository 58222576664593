<div class="d-flex flex-column">
  <div>
    <img src="assets/iStock_000052169644_Full.jpg" class="img-fluid" />
  </div>
  <div class="d-flex flex-row" style="padding-top: 1rem;">
    <div class="flex-grow-1"></div>
    <div style="max-width: 1024px; width: 100%; margin-left: 5px; margin-right: 5px;">
      <div *ngIf="!datenschutz">
        <div *ngIf="hasNoCenter">
          Es ist kein valides Center angegeben.
        </div>
        <div *ngIf="!show && !hasNoCenter">
          <div [innerHTML]="text">
          </div>
          <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
            <div class="form-group">
              <label for="company">Firma*</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && form.controls.company.errors }" id="company"
                formControlName="company" aria-describedby="emailHelp" placeholder="Firma" required />
              <div class="invalid-feedback">
                Bitte geben Sie eine Firma an.
              </div>
            </div>
            <div class="form-group">
              <label for="salutation">Anrede*</label>
              <select class="form-control" id="salutation"
                [ngClass]="{ 'is-invalid': submitted && form.controls.salutation.errors }" formControlName="salutation"
                required>
                <option>Herr</option>
                <option>Frau</option>
                <option>Andere</option>
              </select>
              <div class="invalid-feedback">
                Bitte wählen Sie eine Anrede aus.
              </div>
            </div>
            <div class="form-group">
              <label for="firstname">Vorname*</label>
              <input type="text" class="form-control" id="firstname"
                [ngClass]="{ 'is-invalid': submitted && form.controls.firstname.errors }" formControlName="firstname"
                placeholder="Vorname" required />
              <div class="invalid-feedback">
                Bitte geben Sie einen Vornamen an.
              </div>
            </div>
            <div class="form-group">
              <label for="lastname">Nachname*</label>
              <input type="text" class="form-control" id="lastname"
                [ngClass]="{ 'is-invalid': submitted && form.controls.lastname.errors }" formControlName="lastname"
                placeholder="Nachname" required />
              <div class="invalid-feedback">
                Bitte geben Sie einen Nachnamen an.
              </div>
            </div>
            <div class="form-group">
              <label for="street">Straße*</label>
              <input type="text" class="form-control" id="street"
                [ngClass]="{ 'is-invalid': submitted && form.controls.street.errors }" formControlName="street"
                placeholder="Straße" required />
              <div class="invalid-feedback">
                Bitte geben Sie eine Straße an.
              </div>
            </div>
            <div class="form-group">
              <label for="zipcode">PLZ*</label>
              <input type="text" class="form-control" id="zipcode"
                [ngClass]="{ 'is-invalid': submitted && form.controls.zipcode.errors }" formControlName="zipcode"
                placeholder="PLZ" required />
              <div class="invalid-feedback">
                Bitte geben Sie eine Postleitzahl an.
              </div>
            </div>
            <div class="form-group">
              <label for="city">Stadt*</label>
              <input type="text" class="form-control" id="city"
                [ngClass]="{ 'is-invalid': submitted && form.controls.city.errors }" formControlName="city"
                placeholder="Stadt" required />
              <div class="invalid-feedback">
                Bitte geben Sie eine Stadt an.
              </div>
            </div>
            <div class="form-group">
              <label for="phone">Telefon*</label>
              <input type="text" class="form-control" id="phone"
                [ngClass]="{ 'is-invalid': submitted && form.controls.phone.errors }" formControlName="phone"
                placeholder="Telefon" required />
              <div class="invalid-feedback">
                Bitte geben Sie eine Telefonnummer an.
              </div>
            </div>
            <div class="form-group">
              <label for="email">E-Mail*</label>
              <input type="text" class="form-control" id="email"
                [ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }" formControlName="email"
                placeholder="E-Mail" required />
              <div class="invalid-feedback">
                Bitte geben Sie eine E-Mail Adresse an.
              </div>
            </div>
            <div class="form-group">
              <label for="others">Sonstiges</label>
              <textarea class="form-control" id="others" formControlName="others" rows="3"></textarea>
            </div>

            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="checkboxAdvertising"
                formControlName="checkboxAdvertising" />
              <label class="form-check-label" for="checkboxAdvertising">Ich interessiere mich für Werbung auf der großen
                Leinwand</label>
            </div>
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="checkboxOther" formControlName="checkboxOther" />
              <label class="form-check-label" for="checkboxOther">Ich interessiere mich für sonstige Werbeformen im
                Kino</label>
            </div>

            <div class="d-flex justify-content-center">
              <button type="submit" class="btn send-button">Abschicken</button>
            </div>
          </form>
        </div>
        <div *ngIf="show && !hasNoCenter">
          Ihre Anfrage wurde versendet.
        </div>
      </div>
      <div *ngIf="datenschutz">
        <app-dataschutz></app-dataschutz>
        <button class="link hover" style="color: black; padding-top: 3rem !important;" (click)="hideDatenschutz()">Zurück zum Formular</button>
      </div>
    </div>

    <div class="flex-grow-1"></div>
  </div>
  <div class="footer">
    <button class="link" style="color: white;" (click)="showDatenschutz()">Datenschutz</button>
  </div>
</div>